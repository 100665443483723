
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue";
import CarInfo from "@/components/cardinfo/index.vue";
// import { translate } from "@/utils/anime";
import anime from "animejs";
@Options({
  components: {
    HelloWorld,
    CarInfo,
  },
})
export default class Home extends Vue {
  data() {
    return {};
  }
  mounted() {
    anime
      .timeline({ loop: true })
      .add({
        targets: ".ml15 .word",
        scale: [14, 1],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: (el, i) => 800 * i,
      })
      .add({
        targets: ".ml15",
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000,
      });
  }
}
